import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store";
import OtpInput from "react-otp-input";
import LoaderImg from "../../assets/img/loader.gif";
import { AccountType } from "../../models";
import {
  UserIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  ArrowLeftIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const Otp = () => {
  // console.log("=> Render OTP");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const { verifyOtp, updatePassword } = useAuth();
  const [loader, setLoader] = useState(false);
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [displayResModal, setDisplayResModal] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [isRecovering] = useState<string | null>(
    window.sessionStorage.getItem("nj_recovering")
  );
  // console.log(entries);
  useEffect(() => {
    setPhoneNumber(sessionStorage.getItem("nj_userId") as string);
  }, []);

  const goBack = () => {
    navigate("/");
  };

  const submitHandler = async () => {
    if (!otp) return;
    setLoader(true);
    const res = await verifyOtp(otp);
    if (res && isRecovering) {
      setDisplayPasswordModal(true);
      setLoader(false);
      return;
    }
    if (res) navigate("/register");
    setLoader(false);
  };

  const onChangPassword = async () => {
    sessionStorage.removeItem("nj_recovering");
    if (!password) return;
    const data: any =
      accountType === AccountType.DISTRIBUTOR
        ? {
            user_id: phoneNumber,
            account_type: accountType,
            password,
          }
        : {
            pseudo: window.sessionStorage.getItem("nj_pseudo"),
            account_type: accountType,
            password,
          };
    const { success } = await updatePassword(data);
    console.log(success);
    let message = "Echec du modification du mot de passe.";
    if (success) {
      message =
        "Mot de passe modifié. Veuillez retourner à la page de connexion.";
    }
    setResponse({
      success,
      message,
    });
    setDisplayPasswordModal(false);
    setDisplayResModal(true);
  };

  const onResentOTP = async () => {};

  return (
    <>
      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {displayResModal ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.success ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message}
                </p>
              </>
            )}
            {response?.success ? (
              <div className="flex flex-col gap-4">
                <button
                  onClick={() => {
                    navigate("/login");
                    setDisplayResModal(false);
                  }}
                  className="bg-cyan-500  py-2 rounded-l-full rounded-r-full h-fit text-dark px-16"
                >
                  Se connecter
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {displayPasswordModal ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            $event.stopPropagation();
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase text-tertiary font-bold">
              Changement de mot de passe <br />
            </h2>

            <input
              className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              onChange={($event: any) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre nouveau mot de passe"
            />

            <input
              className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              onChange={($event: any) =>
                setConfirmPassword($event.target.value)
              }
              type="password"
              placeholder="Confirmer le mot de passe"
            />
            {confirmPassword && confirmPassword !== password ? (
              <small className="text-sm text-red-500">
                Mot de passe ne correspondent pas
              </small>
            ) : (
              <></>
            )}

            <button
              onClick={onChangPassword}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confirmer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="h-full flex flex-col items-center justify-between pt-12">
        {/* <h2 className="text-purple-100 text-3xl ">Saisir le code envoyé</h2> */}
        <div className="h-full flex flex-col items-center justify-between pt-12">
          {accountType === AccountType.DISTRIBUTOR ? (
            <div className="space-y-8">
              <h1 className="text-blue-200">Compte Distributeur</h1>
              <UserIcon className="text-blue-200 animate-bounce" />
            </div>
          ) : accountType === AccountType.AGENCY ? (
            <div className="space-y-8">
              <h2 className="text-green-100">Compte Agence</h2>
              <BanknotesIcon className="text-blue-200 animate-bounce" />
            </div>
          ) : accountType === AccountType.COMMERCIAL ? (
            <div className="space-y-8">
              <h2 className="text-purple-100">Compte Commercial</h2>
              <BuildingOffice2Icon className="text-blue-200 animate-bounce" />
            </div>
          ) : (
            <h2 className="text-red-500">Pas de type de compte</h2>
          )}

          <div className="w-full flex justify-center text-white text-xl gap-2">
            <button className="" onClick={goBack}>
              {" "}
              <ArrowLeftIcon className="text-white w-8" />{" "}
            </button>{" "}
            {phoneNumber}
          </div>
        </div>
        <div className="w-[calc(100%+4rem)] h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
          <h3 className="text-primary italic">
            Veuillez saisir le code de vérification envoyé au {phoneNumber}
          </h3>
          {/* <InputGroup entries={entries} setEntries={setEntries} entryHandle={entryHandle} /> */}
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props) => (
              <input
                {...props}
                className="border-b-2 border-primary text-4xl mx-2 "
              />
            )}
          />
          <div>
            <button
              className="bg-secondary px-8 py-2 rounded-l-full rounded-r-full text-dark"
              onClick={submitHandler}
            >
              Confirmez
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
