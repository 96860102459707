import React, { useEffect, useReducer, useState } from "react";
import { AccountType, RegisterDTO } from "../../models";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";
import { useAgence } from "../../store/agence";
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";

const Agences = () => {
  const navigate = useNavigate();
  const { getAgences, addAgence } = useAgence();

  const [agences, setAgences] = useState<any[]>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [state, dispatch] = useReducer(
    (state: any, action: any): any => ({
      ...state,
      ...action,
    }),
    {
      username: "",
      last_name: "",
      middle_name: "",
      first_name: "",
      phone: "",
      password: "",
      birth: "",
      account_type: AccountType.AGENCY,
    }
  );
  useEffect(() => {
    loadAgences();
  }, []);

  const loadAgences = async () => {
    const agences = await getAgences();
    console.log(agences);
    setAgences(agences);
  };

  const onAdd = async () => {
    setDisplayForm(true);
  };

  const onSubmit = async () => {
    const data = await addAgence(state);
    console.log(data);
    loadAgences();
    setDisplayForm(false);
  };

  const goToCommerciaux = async (id: string) => {
    navigate("/dashboard/agence/" + id);
  };

  const goBack = async () => {
    navigate("/dashboard/home");
  };

  return (
    <>
      {displayForm ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayForm(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8 "
          >
            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Nom de famille
              </label>
              <input
                onChange={($event) =>
                  dispatch({ first_name: $event.target.value })
                }
                type="text"
                placeholder="Nom de famille"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
            </div>

            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Prenoms
              </label>
              <input
                onChange={($event) =>
                  dispatch({ last_name: $event.target.value })
                }
                type="text"
                placeholder="Prénoms"
                className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
              />
            </div>

            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Date de naissance
              </label>
              <input
                onChange={($event) => dispatch({ birth: $event.target.value })}
                type="date"
                placeholder="Date de naisssance"
                className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
              />
            </div>

            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Pseudo
              </label>
              <input
                onChange={($event) => dispatch({ username: $event.target.value })}
                type="text"
                placeholder="Pseudo"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
            </div>
            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Contact
              </label>
              <input
                onChange={($event) => dispatch({ phone: $event.target.value })}
                type="phone"
                placeholder="Contact"
                className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
              />
            </div>

            <div className="w-full space-y-1">
              <label className="font-bold" htmlFor="">
                Mot de passe
              </label>
              <input
                onChange={($event) =>
                  dispatch({ password: $event.target.value })
                }
                type="password"
                placeholder="Mot de passe"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
            </div>
            <div className="w-full">
              <button
                className="bg-secondary px-8 py-2 rounded-l-full rounded-r-full text-dark w-full"
                onClick={onSubmit}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button>
          <h3 className="text-lg">Mes Agences</h3>
          <div></div>
        </div>

        <div className="space-y-4 p-8 h-[calc(100%-4rem)] overflow-y-auto">
          <div className="flex justify-between">
            <h2 className="text-primary text-lg">Mes agences</h2>
            <button onClick={onAdd}>Ajouter</button>
          </div>
          <div className="w-full  space-y-4 h-96">
            {agences.map((agence) => (
              <div
                onClick={() => goToCommerciaux(agence.id)}
                key={agence.id}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-2 items-center">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-primary" />
                  <div>
                    <h3 className="text-xs text-primary">
                      {agence.display_name}
                    </h3>
                    <p className="text-sm text-gray-400">{agence.username}</p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">{agence.created_at}</p>
                  <span className="text-sm text-primary">
                    {+agence.cash_balance} F CFA
                  </span>
                </div>
              </div>
            ))}

            {/* <div className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2">
    <div className="p-2 flex gap-2 items-center">
      <ShoppingCartIcon className="w-8 h-8 mx-auto text-secondary" />
      <div>
        <h3 className="text-xs text-secondary">Paiment</h3>
        <p className="text-sm text-gray-400">chez Opéra</p>
      </div>
    </div>
    <div className="text-right">
      <p className="text-xs text-gray-500">12/01/2023 à 8:00</p>
      <span className="text-sm text-secondary">3 500 F CFA</span>
    </div>
  </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Agences;
