import React, { useEffect, useState } from "react";
import { AccountType } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useCommercial } from "../../store";

const Agence = () => {
  const navigate = useNavigate();
  const { geSubCommerciaux } = useCommercial();
  const [agencyId, setAgencyId] = useState<string>();
  const [commerciaux, setCommerciaux] = useState<any[]>([]);
  const params = useParams();

  useEffect(() => {
    const agencyId = params["id"];
    if (setAgencyId) setAgencyId(agencyId);
    loadCommerciaux();
  }, []);

  const loadCommerciaux = async () => {
    if (!agencyId) return;
    const commerciaux = await geSubCommerciaux(agencyId);
    setCommerciaux(commerciaux);
  };

  return (
    <>
      <div>
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <img src={LogoImg} alt="logo" className="w-16 h-16 rounded-full" />
          <h3 className="text-lg">
            Compte
            <select>
              <option value={AccountType.DISTRIBUTOR}>Distributeur</option>
              <option value={AccountType.AGENCY}>Agence</option>
              <option value={AccountType.COMMERCIAL}>Commercial</option>
            </select>
          </h3>
          <div></div>
        </div>

        <div className="space-y-4 p-8">
          <div className="flex justify-between">
            <h2 className="text-primary text-lg">Commerciaux </h2>
          </div>
          <div className="w-full  space-y-4 h-96">
            {commerciaux.map((commercial) => (
              <div
                key={commercial.id}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-2 items-center">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-primary" />
                  <div>
                    <h3 className="text-xs text-primary">
                      {commercial.display_name}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {commercial.username}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {commercial.date} à {commercial.time}
                  </p>
                  <span className="text-sm text-primary">
                    {+commercial.cash_balance} F CFA
                  </span>
                </div>
              </div>
            ))}

            {commerciaux?.length === 0 && (
              <div className="text-center">Aucun commercial trouvé</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Agence;
