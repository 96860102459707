import {
  ArrowLeftIcon,
  Cog6ToothIcon,
  DocumentCheckIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { AccountType, DocumentType } from "../../models";
import { useState, useEffect } from "react";
import { useAuth } from "../../store";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Modal from "../../components/utils/Modal";
import OtpInput from "react-otp-input";
import LogoImg from "../../assets/img/logo.png";

const Information = () => {
  const { getProfile, logout } = useAuth();
  const [displayModal, setDisplayModal] = useState<boolean>();
  const [step, setStep] = useState<string>();
  const [otp, setOtp] = useState("");
  const [action, setAction] = useState<string>();
  const [profile, setProfile] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [pseudo, setPseudo] = useState<string>("");
  const [password, setPassword] = useState<string>();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const navigate = useNavigate();
  const { changePseudo, changePhone, getDocuments } = useAuth();

  useEffect(() => {
    const localProfile = getProfile();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
    load();
  }, []);

  const load = async () => {
    const docs = await getDocuments();
    setDocuments(docs);
  };

  const goBack = () => {
    navigate("/dashboard/profile");
  };

  // password handler
  const passawordHandler = () => {
    setStep(() => action);
  };
  //Edit Profil Handler
  const editPseudoHandler = (e: any) => {
    setDisplayModal(() => true);
    setAction(() => "pseudo");
  };

  const validePseudoHandler = async () => {
    await changePseudo({ usename: pseudo, password });
    setProfile({ ...profile, username: pseudo });
    setDisplayModal(() => false);
    setStep(() => "");
  };
  //Edit Profil Handler
  const editPhoneHandler = (e: any) => {
    setDisplayModal(() => true);
    setAction(() => "phone");
  };

  // edit phone number next step handler
  const nextStepHandler = () => {
    setStep(() => "otp");
  };

  const cancelHandler = () => {
    setDisplayModal(() => false);
    setStep(() => "");
  };

  const otpBackStepHandler = () => {
    setStep(() => "phone");
  };

  const valideOtpHandler = () => {
    console.log(phone);
    setDisplayModal(() => false);
    setStep(() => "");
  };
  return (
    <>
      {displayModal && (
        <Modal>
          {step === "pseudo" ? (
            <div className="w-full h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
              <div className="w-full flex justify-start text-red-500">
                <span onClick={cancelHandler}>Annuler</span>{" "}
              </div>
              <h3 className="text-primary italic">
                Veuillez saisir votre nouveau Pseudo
              </h3>
              <input
                className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                onChange={($event: any) => setPseudo($event.target.value)}
                value={pseudo}
                type="text"
                placeholder=""
              />
              <div>
                <button
                  className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
                  onClick={validePseudoHandler}
                >
                  Confirmez
                </button>
              </div>
            </div>
          ) : step === "phone" ? (
            <div className="w-full h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
              <h3 className="text-primary italic">
                Veuillez saisir votre nouveau
              </h3>
              <PhoneInput
                className=" max-w-[80vw] border   rounded-l-full rounded-r-full text-center   border-primary p-6 rounded h-10 text-primary text-xl"
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />
              <div>
                <button
                  className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
                  onClick={nextStepHandler}
                >
                  Suivant
                </button>
              </div>
            </div>
          ) : step === "otp" ? (
            <div className="flex flex-col items-center space-y-12">
              <span
                onClick={otpBackStepHandler}
                className="w-full flex justify-start gap-1"
              >
                <ArrowLeftIcon className="w-6" />
                Precedent
              </span>
              <h2 className="text-secondary">Veuillez saisir le code reçu</h2>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="border-b-2 border-primary text-4xl mx-2 "
                  />
                )}
              />
              <div>
                <button
                  className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
                  onClick={valideOtpHandler}
                >
                  Confirmez
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
              <div className="w-full flex justify-start text-red-500">
                <span onClick={cancelHandler}>Annuler</span>{" "}
              </div>
              <h3 className="text-primary italic">
                Veuillez saisir votre mot de passe
              </h3>
              <input
                className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                onChange={($event: any) => setPassword($event.target.value)}
                type="password"
                placeholder="Votre mot de passe"
              />
              <div>
                <button
                  className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
                  onClick={passawordHandler}
                >
                  Confirmez
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className="w-full h-full  bg-white p-8">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button>
          <h3>Infos du compte</h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
            <Cog6ToothIcon className="w-8" />
          </button>
        </div>

        {/*  */}
        <div className="w-full mb-8">
          {/*  */}
          <div className="w-full space-y-4">
            <div className="h-32 flex justify-center items-center mb-8">
              <img
                src={profile?.avatar || LogoImg}
                className="h-24 w-24 rounded-full border"
                alt=""
              />
            </div>
            <div className="w-full border-b flex p-1 ">
              <div className="w-1/2">Nom </div>
              <div className="w-1/2 text-primary">: {profile?.name}</div>
            </div>
            <div className="w-full border-b flex p-1 ">
              <div className="w-1/2">Contact </div>
              <div className="w-1/2 text-primary flex justify-between">
                <span>: {profile?.number}</span>
                <PencilSquareIcon
                  onClick={editPhoneHandler}
                  className="w-4 text-black"
                />
              </div>
            </div>
            <div className="w-full border-b flex p-1 ">
              <div className="w-1/2">Pseudo </div>
              <div className="w-1/2 text-primary flex justify-between">
                <span>: {profile?.username || "Néant"}</span>
                <PencilSquareIcon
                  onClick={editPseudoHandler}
                  className="w-4 text-black"
                />
              </div>
            </div>
            <div className="w-full border-b flex p-1 ">
              <div className="w-1/2">Statut </div>
              <div className="w-1/2 text-primary">: Actif</div>
            </div>
          </div>

          {/* documents */}
          <div className="w-full space-y-4 mt-16">
            <h3 className="text-center text-primary font-bold text-lg">
              Mes documents
            </h3>
            {documents?.map((item: any, i: any) => (
              <div
                className="w-full border rounded-lg p-2 flex justify-between"
                key={i}
              >
                <div className="flex gap-2 items-center">
                  <DocumentCheckIcon className="w-8" />
                  <span>{item.name}</span>
                  <a href={item.document}></a>
                </div>
                <span className="text-green-500">{item.status}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
