import { AccountType } from "../models"
import { useApi } from "./api"

export const useCommercial = () => {
    const { $post, $get, objToArray } = useApi()

    /**
     * Get agency commercials by distributor
     * @param agencyId 
     * @returns 
     */
    const geSubCommerciaux = async(agencyId: string) => {
        const { data } = await  $post('account/get_secondary_sub_accounts/', { account_type: AccountType.COMMERCIAL, account_id: agencyId }, false)
        if (data) {
            console.log(data);
            const result = objToArray(data)
            return result
        }
        return [];
    }

    /**
     * Get commercials by Agency
     * @returns 
     */
    const getCommerciaux = async () => {
        const { data } = await $post('account/get_sub_accounts/', { account_type: AccountType.COMMERCIAL}, false)
        if (data) {
            console.log(data);
            // const result = objToArray(data)
            return data
        }
        return [];
    }

    const addCommercial = async (data: any) => {
        try {
            const res = await $post('account/signup/', data, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du commercial!")
        }
    }

    return { addCommercial, getCommerciaux, geSubCommerciaux }

}


