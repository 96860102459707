import { AccountType } from "../models";
import { useApi } from "./api";

export const useAgence = () => {
  const { $post, $get, objToArray } = useApi();

  const getAgences = async () => {
    const res = await $post(
      "account/get_sub_accounts/",
      { account_type: AccountType.AGENCY },
      false
    );
    console.log(res);
    if (res && res.data) {
      // const result = objToArray(res.data)
      return res.data;
    }
    return [];
  };

  const addAgence = async (data: any) => {
    try {
      const res = await $post("account/signup/", data, false);
      console.log(res);
      return res;
    } catch (err) {
      window.alert("Echec d'enregistrement du contact!");
    }
  };

  return { addAgence, getAgences };
};

function objToArray(data: any) {
  throw new Error("Function not implemented.");
}
