import { AccountType } from "../../models";
import LogoImg from "../../assets/img/logo.png";
import {
  ArrowLeftIcon,
  Bars3Icon,
  QrCodeIcon,
  ArrowPathIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useOperation } from "../../store";
import { QrScanner } from "@yudiel/react-qr-scanner";
import ContactsList from "../../components/ContactsList";
import PhoneInput from "react-phone-number-input";

const Home = () => {
  const [accountType, setAccountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>();
  const [balance, setBalance] = useState<number>();
  const [displayQR, setDisplayQR] = useState(false);
  const [displayBalance, setDisplayBalance] = useState(false);
  const { getProfile } = useAuth();
  const navigate = useNavigate();
  const { readQrCode, getOperations, getBalance, getReceiver, deposit } =
    useOperation();
  const [operations, setOperations] = useState<any[]>([]);
  const [operationAccountType, setOperationAccountType] = useState<AccountType>(
    AccountType.AGENCY
  );
  const [operationType, setoperationType] = useState<any>();
  const [scanning, setScanning] = useState(false);
  const [displayOperationMethod, setDisplayOperationMethod] = useState(false);
  const [receiver, setReceiver] = useState<any>();
  const [displayAmountModal, setDisplayAmountModal] = useState(false);
  const { verifyAccount } = useAuth();
  const [chekReceiverRes, setCheckReceiverRes] = useState<any>();
  const [amount, setAmount] = useState(0);
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [displayContactsList, setDisplayContactsList] = useState(false);
  const [usePseudo, setUsePseudo] = useState(false);
  const [phone, setPhone] = useState<any>();

  useEffect(() => {
    const localProfile = getProfile();
    updateBalance();
    getLastOperations();
    // loadBankAccounts();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);
  const [pseudo, setPseudo] = useState("");

  const updateBalance = async () => {
    const balance = await getBalance();
    setBalance(+balance);
  };

  const switchDisplayQr = (val: boolean) => {
    setDisplayQR(val);
  };

  const getLastOperations = async () => {
    const data = await getOperations({});
    // const formatedData: any[] = [];
    // Object.keys(data).map((item, i) => {
    //   const operation = Object.keys(data[item]).map((key, i) => ({
    //     id: key,
    //     date: item,
    //     ...data[item][key],
    //   }));
    //   formatedData.push(...operation);
    // });
    // console.log("last operations", formatedData);
    setOperations(data.slice(0, 3));
  };

  const switchBalanceDisplay = async () => {
    updateBalance();
    setDisplayBalance(!displayBalance);
    if (!displayBalance) setTimeout(() => setDisplayBalance(false), 5000);
  };

  const onInitOperation = (operationType: any) => {
    if (operationType === "recharge") {
      setoperationType(operationType);
      setDisplayQR(true);
    } else if (operationType === "agency-recharge") {
      setoperationType("recharge");
      if (accountType === AccountType.DISTRIBUTOR)
        setOperationAccountType(AccountType.AGENCY);
      else if (accountType === AccountType.AGENCY)
        setOperationAccountType(AccountType.COMMERCIAL);
      else if (accountType === AccountType.COMMERCIAL)
        setOperationAccountType(AccountType.KIOSQUE);
      setDisplayOperationMethod(true);
      setScanning(true);
    }
    // else if (operationType === "deposit") {
    //   setOperationAccountType(AccountType.COMMERCIAL);
    //   setDisplayDepositQR(true);
    // } else if (operationType === "payment") {
    //   setOperationAccountType(AccountType.AGENCY);
    //   setDisplayPaymentMethod(true);
    //   setScanning(true);
    // }
  };

  const scanQr = async (val: string) => {
    if (val) setScanning(false);

    const receiver = await readQrCode(val);
    // receiver.id = 'receiver_' + receiver.id;
    setReceiver(receiver);
    setDisplayOperationMethod(false);
    setDisplayAmountModal(true);
  };

  const onCheckIdentfier = async () => {
    const req: any = { account_type: operationAccountType, pseudo };

    const res = await verifyAccount(req);
    setCheckReceiverRes(res);
    console.log("result", res);
  };

  const onValidateIdentifier = async () => {
    console.log("runed");
    // displayLoader();

    let req: any = { receiver_type: operationAccountType };
    if (usePseudo) req["receiver_username"] = pseudo;
    else req["receiver_id"] = phone;
    const receiver = await getReceiver(req);
    setReceiver(receiver);
    setDisplayOperationMethod(false);
    setDisplayAmountModal(true);
    // else {
    //   let receiver_type;
    //   if (operationType === "transfert") receiver_type = AccountType.CLIENT;
    //   if (operationType === "withdraw" || operationType === "deposit") {
    //     setDisplayWithdrawalMethod(false);
    //     receiver_type = AccountType.kIOSQUE;
    //   }

    //   if (operationType === "payment") receiver_type = AccountType.BUSINESS;
    //   req = { receiver_id: phone, receiver_type };
    // }
    // const receiver = await getReceiver(req);
    // if (receiver.res === "failed") {
    //   window.alert(receiver.message);
    //   return;
    // }
    // setReceiver(receiver);
    // setDisplayOperationMethod(false);
    // setDisplayWithdrawalMethod(false);
    // setDisplayPaymentMethod(false);
    // setDisplayAmountModal(true);
  };

  const onValidateAmount = async () => {
    // await displayLoader();
    await setDisplayAmountModal(false);
    await setDisplayPasswordModal(true);
  };

  const goToAgences = () => {
    navigate("/dashboard/agences");
  };

  const goToCommerciaux = () => {
    navigate("/dashboard/commerciaux");
  };

  const onMakeOperation = async () => {
    const res = await deposit(
      operationAccountType,
      receiver.id,
      amount,
      password
    );
    console.log(res);
    setDisplayPasswordModal(false);
    getLastOperations();
  };

  const selectContact = async (contact?: any) => {
    setDisplayContactsList(false);
    if (contact) {
      const receiver = await getReceiver({
        receiver_username: contact.username,
        receiver_type: operationAccountType,
      });
      console.log(receiver);
      setReceiver(receiver);
      setDisplayOperationMethod(false);
      setDisplayAmountModal(true);
    }
  };

  return (
    <>
      {displayQR ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => switchDisplayQr(false)}
        >
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl p-16 z-50">
            <h3 className="text-center">
              Presenter votre QR code pour effectuer l'opération
            </h3>
            <img src={profile?.qr_image} className="w-48 h-48 mx-auto" alt="" />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* identifier */}
      {displayOperationMethod ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperationMethod(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">{operationType}</h2>
            {scanning ? (
              <QrScanner
                onDecode={scanQr}
                onError={(error: any) => console.log(error?.message)}
              />
            ) : (
              <>
                {usePseudo ? (
                  <>
                    {" "}
                    <input
                      onChange={($event) => setPseudo($event.target.value)}
                      onKeyUp={onCheckIdentfier}
                      type="text"
                      placeholder="Entrer le pseudo"
                      className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
                    />
                    {/* <small className="text-secondary absolute uppercase">
                  {chekReceiverRes?.name}
                </small>
                */}
                    <button
                      disabled={!pseudo}
                      onClick={onValidateIdentifier}
                      className="bg-secondary px-16 py-2 rounded-l-full w-full rounded-r-full  text-dark"
                    >
                      Continuer
                    </button>
                  </>
                ) : (
                  <>
                    <PhoneInput
                      className="w-fulloutline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-xl"
                      placeholder="Entre le contact"
                      value={phone}
                      onChange={setPhone}
                    />
                    :
                    {/* <small className="text-secondary absolute uppercase">
                  {chekReceiverRes?.name}
                </small>
                */}
                    <button
                      disabled={!phone}
                      onClick={onValidateIdentifier}
                      className="bg-secondary px-16 py-2 rounded-l-full w-full rounded-r-full  text-dark"
                    >
                      Continuer
                    </button>
                  </>
                )}
              </>
            )}
            <div className="w-full space-y-2 text-center">
              <div className="w-full flex justify-between items-center">
                <hr className="w-2/5" />
                <span className="uppercase font-serif">ou</span>
                <hr className="w-2/5" />
              </div>
              <div className="w-full space-y-4">
                <button
                  onClick={() => setDisplayContactsList(true)}
                  className="bg-cyan-700 flex gap-4 items-center justify-center w-full py-2 rounded-l-full rounded-r-full h-fit text-white"
                >
                  {accountType === AccountType.DISTRIBUTOR ? (
                    <>
                      {" "}
                      <BuildingOffice2Icon className="w-4 h-4" />
                      Mes Agences
                    </>
                  ) : accountType === AccountType.AGENCY ? (
                    <>
                      {" "}
                      <UsersIcon className="w-4 h-4" />
                      Mes commerciaux
                    </>
                  ) : (
                    <>
                      <UsersIcon className="w-4 h-4" />
                      Mes contacts
                    </>
                  )}
                </button>
                <button
                  onClick={() => {
                    setScanning(false);
                    setUsePseudo(false);
                  }}
                  className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-teal-700"
                >
                  <UserIcon className="w-4 h-4  text-white" />
                  <span className="text-sm">Utiliser le téléphone</span>
                </button>
                <button
                  onClick={() => {
                    setScanning(false);
                    setUsePseudo(true);
                  }}
                  className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-primary"
                >
                  <UserIcon className="w-4 h-4  text-white" />
                  <span className="text-sm">
                    Utiliser le Pseudo du receveur
                  </span>
                </button>
                <button
                  onClick={() => setScanning(true)}
                  className="p-2 flex w-full justify-center  font-bold gap-4 items-center text-white  rounded-l-full rounded-r-full bg-yellow-500"
                >
                  <QrCodeIcon className="w-4 h-4 text-white" />
                  <span className="text-sm">
                    Scanner le QR Code du receveur
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Amount */}
      {displayAmountModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperationMethod(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération avec{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant en F CFA"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!amount}
              onClick={onValidateAmount}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Effectuer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Pin */}
      {displayPasswordModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperationMethod(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">Mot de passe</h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération de{" "}
              <span className="text-tertiary">{amount}</span> avec{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onMakeOperation}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* ContactList */}
      {displayContactsList ? (
        <ContactsList accountType={operationAccountType} cb={selectContact} />
      ) : (
        <></>
      )}

      <div className="w-full h-full overflow-hidden">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <img src={LogoImg} alt="logo" className="w-16 h-16 rounded-full" />
          <h3 className="text-lg">
            Compte
            {accountType === AccountType.DISTRIBUTOR
              ? " Distributeur"
              : accountType === AccountType.AGENCY
              ? " Agence"
              : " Commercial"}
          </h3>
          {displayMenu ? (
            <button
              onClick={() => setDisplayMenu(false)}
              className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
            >
              <ArrowLeftIcon className="w-8" />
            </button>
          ) : (
            <button
              onClick={() => setDisplayMenu(true)}
              className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
            >
              <Bars3Icon className="w-8" />
            </button>
          )}
        </div>
        <div className="space-x-4 flex items-center p-2 ">
          {" "}
          <h3 className="text-primary font-bold text-lg">
            Salut {profile?.username} !
          </h3>
        </div>
        <div className="w-full p-8">
          <div
            className={`${
              accountType === AccountType.DISTRIBUTOR
                ? "bg-secondary text-white"
                : accountType === AccountType.COMMERCIAL
                ? "bg-teal-300 text-dark"
                : "bg-tertiary text-white"
            } w-full h-36 flex flex-col justify-between shadow-lg rounded-xl  font-serif p-4`}
          >
            <div className="w-full flex justify-between">
              <button onClick={() => switchDisplayQr(true)}>
                <QrCodeIcon className="w-8 h-8" />
              </button>

              {displayBalance ? (
                <span>{balance || 0} F</span>
              ) : (
                <button
                  onClick={switchBalanceDisplay}
                  className="px-2 border border-white rounded-lg"
                >
                  Solde
                </button>
              )}
            </div>
            <div className="w-full flex justify-center text-xl">
              <h3 className="uppercase">{profile?.name}</h3>
            </div>
            <div>
              <h4 className="text-center font-mono text-primary">
                {profile?.number}
              </h4>
            </div>
          </div>
        </div>

        {/* Operations */}

        <div className="space-y-4 p-8">
          <h2 className="text-primary text-lg">Operations</h2>
          {accountType === AccountType.DISTRIBUTOR ? (
            <div className="w-full flex justify-center  items-center gap-2">
              <div
                onClick={() => onInitOperation("recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-primary">
                  <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Approvisions</h3>
              </div>

              <div
                onClick={() => goToAgences()}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-tertiary">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Mes Agences</h3>
              </div>

              <div
                onClick={() => onInitOperation("agency-recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-red-500">
                  <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Recharger</h3>
              </div>
            </div>
          ) : accountType === AccountType.AGENCY ? (
            <div className="w-full flex justify-center  items-center gap-2">
              <div
                onClick={() => onInitOperation("recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-primary">
                  <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Approvisions</h3>
              </div>

              <div
                onClick={() => goToCommerciaux()}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-tertiary">
                  <UsersIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Commerciaux</h3>
              </div>

              <div
                onClick={() => onInitOperation("agency-recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-red-500">
                  <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Recharger</h3>
              </div>
            </div>
          ) : accountType === AccountType.COMMERCIAL ? (
            <div className="w-full flex justify-center  items-center gap-2">
              <div
                onClick={() => onInitOperation("recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-primary">
                  <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Approvisions</h3>
              </div>

              {/* <div
                onClick={() => goToAgences()}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-tertiary">
                  <BuildingOffice2Icon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Mes Agences</h3>
              </div> */}

              <div
                onClick={() => onInitOperation("agency-recharge")}
                className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
              >
                <div className="p-2 rounded-full bg-red-500">
                  <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
                </div>

                <h3 className="text-xs text-center">Recharger</h3>
              </div>
            </div>
          ) : (
            <div>No operation found for this type of account!</div>
          )}
        </div>

        {/* Historique */}
        <div className="space-y-4 p-4">
          <h2 className="text-primary text-lg">Mes dernières opérations</h2>
          <div className="w-full  space-y-2 overflow-y-auto">
            {operations.map((operation) => (
              <div
                key={operation.id}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-4 items-center">
                  {/* <img src={'http://localhost:8000' + operation.icon} className="w-8 h-8 rounded-full"  alt="" /> */}
                  <div>
                    <h3 className="text-xs text-primary capitalize">
                      {operation.transaction_type}{" "}
                      {operation.sender_pseudo !== profile.username
                        ? "de"
                        : "à"}
                      :{" "}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {operation.receiver_name} ({operation.receiver_pseudo})
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {operation.created_at}
                  </p>
                  <span className="text-sm text-primary">
                    {+operation.amount} F CFA
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
